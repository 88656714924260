/*
 * IMPORTS
 */
import '@redq/reuse-modal/lib/index.css' // NPM: Reuse modal css library.
import React from 'react' // NPM: react.js library.
import PropTypes from 'prop-types' // NPM: prop-types library.
import Dynamic from 'next/dynamic' // NPM: Dynamically import from Next.js.
import Head from 'next/head' // NPM: next.js header utility.


/*
 * COMPONENTS
 */
const Charts = Dynamic(() => import('~packages/common/containers/Charts'))
const Navigation = Dynamic(() => import('~packages/common/containers/Navigation'))
const TotalCollectionCounter = Dynamic(() => import('~packages/common/containers/TotalCollectionCounter'))
const NewHomesCounter = Dynamic(() => import('~packages/common/containers/NewHouseCounter'))
const ActiveCollectorsCounter = Dynamic(() => import('~packages/common/containers/ActiveCollectorsCounter'))
const CollectionActivityChart = Dynamic(() => import('~packages/common/containers/CollectionActivityChart'))
const CollectionOvertimeChart = Dynamic(() => import('~packages/common/containers/HouseRegistrationChart'))
const RegistrationOvertimeChart = Dynamic(() => import('~packages/common/containers/AccountRegistrationChart'))
const CollectorList = Dynamic(() => import('~packages/common/containers/CollectorList'))


/*
 * OBJECTS
 */
const Index = ({
  flexRow,
  layout,
  filterSection
}) => (
  <>
    <Head>
      <title>Home | Garbit Dashboard</title>
      <meta name='Description' content='Home | Garbit Dashboard' />
    </Head>
    <main style={flexRow}>
      <Navigation />
      <section style={layout}>
        <section style={filterSection} />
        <Charts
          headerChildren={
            <>
              <TotalCollectionCounter />
              <NewHomesCounter />
              <ActiveCollectorsCounter />
            </>
          }
          bodyChildren = {
            <>
              <CollectionActivityChart />
              <CollectorList />
            </>
          }
          footerChildren={
            <>
              <CollectionOvertimeChart />
              <RegistrationOvertimeChart />
            </>
          }
        />
      </section>
    </main>
  </>
)


/*
 * PROPTYPES
 */
Index.propTypes = {
  'filterSection': PropTypes.object,
  'layout': PropTypes.object,
  'flexRow': PropTypes.object
}
Index.defaultProps = {
  'flexRow': {
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'row',
    'maxHeight': '100vh',
    'overflow': 'hidden'
  },
  'filterSection': {
    'display': 'flex',
    'flexDirection': 'column',
    'padding': '40px 0',
    'overflow': 'hidden',
    'height': '100vh',
    'flex': 0.15
  },
  'layout': {
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'flex-start',
    'width': '100%',
    'overflow': 'auto'
  }
}


/*
 * EXPORTS
 */
export default Index

